<template>
  <div id="app" ref="home">
    <div v-html="statistics"></div>
    <!-- {{statistics}} -->
    <div class="instance" @click="addImage" @touchstart="touchStart" @touchend="touchEnd">
      <img
        style="width: 100%; height: 100%"
        src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/driving_license_example_20210311101508.png"
        alt
      />
      <van-image-preview
        v-model="isShowPreview"
        v-if="isShowPreview"
        :images="previewImages"
        :loop="false"
        @close="onClose()"
      ></van-image-preview>
      <!-- 长按 保存手机 事件 -->
      <van-action-sheet v-model="touchPressHidden" :actions="actions" @select="touchPress" />
    </div>
    <!-- ocr上传 -->
    <div class="ocrIdentify">
      <div class="ocrIdentifyTop">
        <img style="width: 24px; height: 24px" src="@/assets/drivingPermit.png" alt />
        <div class="title">上传行驶证照片</div>
        <div @click="helpClick" class="help">帮助</div>
        <van-icon @click="helpClick" name="question-o" size="14px" class="iconC" bindtap="explain" />
      </div>
      <div class="ocrImageView">
        <van-uploader :after-read="touchPhoto" name="drivingLicenseImage">
          <img
            v-show="!valuationInfo.ossUrl"
            src="@/assets/back.png"
            style="width: 100%; height: 100%"
            alt
          />
          <img
            v-show="valuationInfo.ossUrl"
            :src="valuationInfo.ossUrl"
            alt
            style="width: 100%; height: 100%"
          />
          <!-- <van-button icon="plus" type="primary">上传文件</van-button> -->
        </van-uploader>
      </div>
    </div>
    <!-- 提示 -->
    <van-dialog
      :confirmButtonText="promptTitle"
      v-model="promptHidden"
      title="提示"
      @confirm="isActive = false"
      confirmButtonColor="#507496"
    >
      <div class="promptView">
        <div class="promptTextSize">车型及识别代号很重要哦！</div>
        <div class="promptTextSize mar10">请再核验一次吧！</div>
      </div>
    </van-dialog>
    <div class="carInfoView">
      <div class="ocrIdentifyTop" style="padding: 0px 10px 10px">
        <img style="width: 24px; height: 24px" src="@/assets/fa.png" alt />
        <div class="title">车辆信息</div>
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车牌号码</div>
        <input
          v-model="valuationInfo.PlateNo"
          type="text"
          :readOnly="readOnly"
          placeholder="赣C90***"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车辆类型</div>
        <input
          type="text"
          v-model="valuationInfo.VehicleType"
          :readOnly="readOnly"
          placeholder="重型自卸***"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">车辆品牌</div>
        <input type="text" v-model="valuationInfo.brand" :readOnly="readOnly" placeholder="**牌" />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText" :class="[isActive ? 'carInfoViewLiActive' : '']">车辆型号</div>
        <!-- :placeholder-class="[errorClass == 0 ? 'errorClass' : '']" -->
        <input
          type="text"
          v-model="valuationInfo.vehicleModel"
          :readOnly="readOnly"
          @input="vehicleModelBlur"
          placeholder="DFH****"
        />
        <van-icon
          style="margin-left: 10px"
          @click="onClickVehicleModel"
          name="arrow-down"
          size="14px"
        />
      </div>
      <!-- 车辆型号的弹出层 -->
      <van-popup v-model="vehicleModelHidden" round position="bottom" :style="{ height: '40%' }">
        <van-picker
          title="车辆型号"
          show-toolbar
          :columns="valuationInfo.vehicleModelList"
          @confirm="onConfirmVehicleModel"
          @cancel="onCancelVehicleModel"
        />
      </van-popup>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText" :class="[isActive ? 'carInfoViewLiActive' : '']">车辆识别代号</div>
        <input
          type="text"
          v-model="valuationInfo.Vin"
          :readOnly="readOnly"
          placeholder="LGAX4*******"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">发动机号码</div>
        <input
          type="text"
          v-model="valuationInfo.EngineNo"
          :readOnly="readOnly"
          placeholder="JA4S***"
        />
      </div>
      <div class="carInfoViewLi" @click="RegisterDateClick">
        <div class="carInfoViewLiText">注册日期</div>
        <input type="text" v-model="valuationInfo.RegisterDate" readonly placeholder="请选择注册日期" />
        <div class="zixiSecondFlex" v-if="timeHidden">日期错误</div>

        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="RegisterDateHidden">
        <van-datetime-picker
          v-model="RegisterDateH5"
          type="date"
          title="注册日期"
          @cancel="RegisterCancel"
          @confirm="RegisterConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
      <div class="carInfoViewLi" @click="IssueDateClick">
        <div class="carInfoViewLiText">发证日期</div>
        <input type="text" v-model="valuationInfo.IssueDate" readonly placeholder="请选择发证日期" />
        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="IssueDateHidden">
        <van-datetime-picker
          v-model="IssueDateDateH5"
          type="date"
          title="发证日期"
          @cancel="IssueCancel"
          @confirm="IssueConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
    </div>
    <!-- 基础信息部分 -->
    <div class="basisInfoView">
      <div class="ocrIdentifyTop">
        <img style="width: 24px; height: 24px" src="@/assets/mark.png" alt />
        <div class="title">基础信息</div>
      </div>

      <div class="basisInfoViewLi">
        <img style="width: 16px; height: 16px" src="@/assets/homeClass.png" alt />
        <div class="basisInfoViewLiText">车辆注册地址</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          readonly
          v-model="valuationInfo.locale"
          placeholder="请选择车辆注册地址"
          @click="changeRegin"
        />
        <van-icon style="margin-left: 10px" name="arrow-down" size="14px" />
      </div>
      <!-- 省市区 -->
      <van-popup position="bottom" :style="{ height: '60%' }" round v-model="reginHidden">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="reginHidden = false"
          @finish="onFinish"
          :field-names="fieldNames"
          active-color="#013667"
        />
      </van-popup>
      <div class="basisInfoViewLi" style="margin-top: 6px">
        <img style="width: 16px; height: 14px" src="@/assets/mileage.png" alt />
        <div class="basisInfoViewLiText">表征里程(km)</div>
        <!-- :readOnly="readOnly" -->
        <input
          type="text"
          readonly
          @touchstart.stop="mileageClick"
          clickable
          placeholder="请输入行驶里程"
          v-model="mileage"
        />
        <van-number-keyboard
          v-model="mileage"
          :show="mileageHidden"
          :maxlength="6"
          @blur="mileageHidden = false"
        />
      </div>
    </div>
    <!-- 车辆配置选项  -->
    <div class="configurationView" v-if="valuationInfo.isView">
      <div class="ocrIdentifyTop">
        <img style="width: 24px; height: 24px" src="@/assets/setUp.png" alt />
        <div class="title">配置选项</div>
      </div>
      <div class="configureCen">
        <div class="picker" @click="engineNameClick">
          <div class="pickerLi">
            <div>发动机型号</div>
            <van-icon name="arrow-down" size="14px" />
          </div>
          <div class="pickerText">{{ engineBrand }}{{ engineModel }}</div>
        </div>
        <!-- 发动机型号弹出层 -->
        <van-popup position="bottom" :style="{ height: '38%' }" round v-model="engineNameHidden">
          <!-- :columns="valuationInfo.engine" -->
          <van-picker
            show-toolbar
            title="发动机型号"
            @cancel="engineNameHidden = false"
            @confirm="engineNameConfirm"
            :columns="engineNameColumns"
            :values="engineNameValue"
          />
        </van-popup>
        <div class="picker" @click="drivingTypeClick">
          <div class="pickerLi">
            <div>驱动形式</div>
            <van-icon name="arrow-down" size="14px" />
          </div>
          <div class="pickerText">{{ drivingTypeSelect }}</div>
        </div>
        <van-popup position="bottom" :style="{ height: '38%' }" round v-model="drivingTypeHidden">
          <van-picker
            title="驱动形式"
            show-toolbar
            :columns="drivingTypeColumns"
            @confirm="drivingTypConfirm"
            @cancel="drivingTypeHidden = false"
          />
        </van-popup>
        <div class="picker" @click="speedRatioClick">
          <div class="pickerLi">
            <div>速比</div>
            <van-icon name="arrow-down" size="14px" />
          </div>
          <div class="pickerText">{{ speedRatioSelect }}</div>
        </div>
        <van-popup position="bottom" :style="{ height: '38%' }" round v-model="speedRatioHidden">
          <van-picker
            title="速比"
            show-toolbar
            :columns="speedRatioColumns"
            @confirm="speedRatioConfirm"
            @cancel="speedRatioHidden = false"
          />
        </van-popup>
        <div class="picker" @click="gearboxClick">
          <div class="pickerLi">
            <div>变速器</div>
            <van-icon name="arrow-down" size="14px" />
          </div>
          <div class="pickerText">{{ gearboxSelect }}</div>
        </div>
        <van-popup position="bottom" :style="{ height: '38%' }" round v-model="gearboxHidden">
          <van-picker
            title="变速器"
            show-toolbar
            :columns="gearboxColumns"
            @confirm="gearboxConfirm"
            @cancel="gearboxHidden = false"
          />
        </van-popup>
      </div>
    </div>
    <!-- 车辆图片 -->
    <div class="carImageView">
      <div class="ocrIdentifyTop">
        <img style="width: 24px; height: 21px" src="@/assets/image.png" alt />
        <div class="title">车辆照片</div>
      </div>
      <div class="carImageViewFlex">
        <div
          class="carImageViewFlexLi"
          v-for="(item, index) in ImgList"
          v-show="index < imgNumber"
          :key="item.name"
        >
          <van-uploader :after-read="additionalImage" :name="item.name">
            <div class="image">
              <!-- 顶部加号 -->
              <img v-if="!item.imgUrl" src="@/assets/add.png" style="width: 24px; height: 24px" alt />
              <!-- 删除图片 -->
              <img
                v-if="item.imgUrl"
                class="remoteImage"
                src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/error_symbol_20210527.png"
                alt
                @click="imageDelete(item, index)"
              />
              <!-- 用户上传图片 -->
              <img
                :key="item.name"
                v-if="item.imgUrl"
                style="width: 100%; height: 100%"
                :src="item.imgUrl"
                alt
              />
            </div>
          </van-uploader>
          <div class="text">{{ item.cname }}</div>
        </div>
      </div>
      <button @click="addCarImageClick" v-if="imgNumber < 20" class="carImageBtn">添加车辆照片</button>
    </div>
    <!-- 下一步按钮 -->
    <button class="nextStep" @click="nextStepClick">下一步</button>
    <!-- 进口车型 -->
    <van-dialog
      confirm-button-color="#507496"
      v-model="importedModelsShow"
      @confirm="windowOpen"
      confirm-button-text="立即填写"
      title="提示"
      :show-cancel-button="false"
    >
      <div style="color: #999999;font-size:16px;text-align:center;line-height:50px">进口车型，需更多信息确认</div>
    </van-dialog>
    <!-- 弹出信息 -->
    <van-popup
      @close="closeMore"
      round
      v-model="moreShow"
      position="bottom"
      :style="{ height: '44%' }"
    >
      <div class="g-content">
        <div class="g-title">更多信息</div>
        <ul class="g-brand">
          <li class="g-name">品牌</li>
          <li>{{valuationInfo.brand}}</li>
        </ul>
        <!-- 车系 -->
        <ul class="g-car g-brand" @click="seriesShow = true">
          <li class="g-items">
            <div class="g-name">车系</div>
            <div class="g-select">
              <div v-if="!series">请选择</div>
              <div v-if="series">{{series}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 马力 -->
        <ul class="g-car g-brand" @click="getHorse">
          <li class="g-items">
            <div class="g-name" v-if="horse">马力</div>
            <div class="g-name" v-if="!horse" style="color:#cccccc">马力</div>
            <div class="g-select">
              <div v-if="!horse" style="color:#cccccc">请选择</div>
              <div v-if="horse">{{horse}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 驱动 -->
        <ul class="g-car g-brand" @click="getDrive">
          <li class="g-items">
            <div class="g-name" v-if="drive">驱动</div>
            <div class="g-name" v-if="!drive" style="color:#cccccc">驱动</div>
            <div class="g-select">
              <div v-if="!drive" style="color:#cccccc">请选择</div>
              <div v-if="drive">{{drive}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <!-- 排放 -->
        <ul class="g-car g-brand" @click="dischargeShow=true">
          <li class="g-items">
            <div class="g-name">排放</div>
            <div class="g-select">
              <div v-if="!discharge">请选择</div>
              <div v-if="discharge">{{discharge}}</div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </li>
        </ul>
        <div class="g-tips">提醒：进口车辆，车辆型号请以反馈的数据为准。</div>
        <div class="g-btn" @click="submit">确定</div>
      </div>
    </van-popup>
    <!-- 车系 -->
    <van-popup round v-model="seriesShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="车系"
        show-toolbar
        :columns="seriesColumns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 马力 -->
    <van-popup round v-model="horseShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="马力"
        show-toolbar
        :columns="horseColumns"
        @confirm="onConfirmHorse"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 驱动 -->
    <van-popup round v-model="driveShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="驱动"
        show-toolbar
        :columns="driveColumns"
        @confirm="onConfirmDrive"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 排放 -->
    <van-popup round v-model="dischargeShow" position="bottom" :style="{ height: '44%' }">
      <van-picker
        round
        title="排放"
        show-toolbar
        :columns="dischargeColumns"
        @confirm="onConfirmDischarge"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>
<script type="text/javascript" src='https://c.cnzz.com/core.php?web_id=1281102051&t=z' charset="utf-8">
</script>
<script>
import { Toast } from "vant";
// import { Popup } from "vant";
// import { Icon } from 'vant';
import {
  ocrConsumer,
  getVehicleOptions,
  upPicture,
  getImportedVehicleOptions,
} from "@/utils/http.js"; //路径注意
import { formatDateYMM } from "@/utils/time.js";
import { city } from "@/utils/pca-code.js";
import { debounce } from "@/utils/common.js";
import { Dialog } from "vant";
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      statistics: "",
      // 进口车型
      seriesShow: false, //车系弹窗
      series: "", //车系数据
      importedModelsShow: false, //进口车型弹窗
      moreShow: false, //填写信息
      seriesColumns: ["杭州", "宁波", "温州"],
      horseShow: false,
      horseColumns: ["杭州", "宁波"],
      horse: "",
      driveShow: false,
      drive: "",
      driveColumns: ["杭州", "宁波", "温州"],
      dischargeShow: false,
      discharge: "",
      dischargeColumns: ["国四", "国五", "国六"],
      // 进口车型
      promptHidden: false, // 三秒提示框显示隐藏
      promptTitle: "确认(3)", //三秒提示框文案
      promptTimer: null, //三秒提示框的倒计时
      isActive: false, //车辆类型/车辆识别代码 标红，三秒后为 false
      previewImages: [
        "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/driving_license_example_img_20210311163152.png",
      ],
      isShowPreview: false, //显示隐藏
      touchPressHidden: false, //保存手机的显示隐藏
      actions: [{ name: "保存图片" }, { name: "取消" }],
      timeOutEvent: null,
      // ocr部分参数
      readOnly: true, //input禁止输入
      RegisterDateHidden: false, //注册日期显示隐藏
      IssueDateHidden: false, //发证日期显示隐藏
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 10, 1),
      RegisterDateH5: new Date(), //注册日期显示
      IssueDateDateH5: new Date(), //发证日期显示
      errorClass: 0, //错误展示标红
      timeHidden: false, // 注册日期红
      // 省市区
      reginHidden: false, //省市区的显示隐藏
      cascaderValue: "",
      options: [],
      fieldValue: "",
      fieldNames: {
        text: "label",
        value: "value",
        children: "children",
      },
      engineNameValue: {
        text: "engineName",
        children: "engineModels",
      },
      // 行驶里程
      mileageHidden: false,
      mileage: "",
      // 发动机
      engineNameHidden: false, // 发动机型号显示隐藏
      engineNameColumns: [], //发动机配置
      engineBrand: "", //发动机品牌
      engineModel: "", // 发动机型号
      // 驱动形式
      drivingTypeHidden: false, //驱动形式显示隐藏
      drivingTypeColumns: [],
      drivingTypeSelect: "", //选择驱动
      // 速比
      speedRatioHidden: false, //速比显示隐藏
      speedRatioColumns: [],
      speedRatioSelect: "", //选择速比
      // 变速形式
      gearboxHidden: false, // 变速形式的显示隐藏
      gearboxColumns: [],
      gearboxSelect: "", //选择变速器
      // 上传图片
      imgNumber: 0, //默认0个
      ImgList: [], // 进入页面获取的图片
      // 数据
      valuationInfo: {},
      // 模拟数据
      //车辆型号的展示隐藏
      vehicleModelHidden: false,

      pic: {
        success: true,
        code: 200,
        message: "success",
        data: {
          charts: [
            { name: "urlPic0", cname: "正前" },
            { name: "urlPic1", cname: "左前侧" },
            { name: "urlPic2", cname: "右前侧" },
            { name: "urlPic3", cname: "左侧" },
            { name: "urlPic4", cname: "右侧" },
            { name: "urlPic5", cname: "正后" },
            { name: "urlPic6", cname: "仪表盘(点火状态)" },
            { name: "urlPic7", cname: "中控" },
            { name: "urlPic8", cname: "排挡杆" },
            { name: "urlPic9", cname: "前轮" },
            { name: "urlPic10", cname: "中轮" },
            { name: "urlPic11", cname: "后轮" },
            { name: "urlPic12", cname: "发动机" },
            { name: "urlPic13", cname: "变速器" },
            { name: "urlPic14", cname: "驱动桥" },
            { name: "urlPic15", cname: "车辆铭牌" },
            { name: "urlPic16", cname: "登记证书" },
            { name: "urlPic17", cname: "其他" },
          ],
        },
      },
      falseData: {
        appKey: "1",
        sign: "1",
        randomStr: "1",
        expireTime: "1",
        token: 1,
      },
    };
  },
  methods: {
    closeMore() {
      let a = 3;
      let that = this;
      this.promptHidden = true;
      this.isActive = true;
      // 定时器 三秒后关闭
      // console.log("111");
      this.promptTimer = setInterval(function () {
        a--;
        // console.log(a);
        if (a === -1) {
          clearInterval(that.promptTimer);
          that.promptHidden = false;
          that.isActive = false;
          return;
        }
        that.promptTitle = `确认(${a})`;
      }, 1000);
    },
    async submit() {
      let list = {
        field: "model",
        brand: this.valuationInfo.brand,
        series: this.series,
        horsepower: this.horse,
        emissionStd: this.discharge,
        driveType: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || '',
      };
      const { data: res } = await getImportedVehicleOptions(list);
      // if (res.data.result.failureReason) {
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      // }

      // console.log(res)
      // console.log(res.data.result.content[0])
      this.valuationInfo.vehicleModel = res.data.result.content[0];
      this.valuationInfo.vehicleModelList = res.data.result.content;
      this.moreShow = false;
      // this.closeMore()
      let a = 3;
      let that = this;
      this.promptHidden = true;
      this.isActive = true;
      // 定时器 三秒后关闭
      // console.log("111");
      this.promptTimer = setInterval(async function () {
        a--;
        // console.log(that.promptTimer, "========");
        if (a == -1) {
          // console.log(a, "----------");
          await clearInterval(that.promptTimer);
          that.promptHidden = false;
          that.isActive = false;
          return;
        } else {
          if (a == -2) {
            // clearInterval(that.promptTimer);
            a = 0;
          }
          // console.log(a, "============");
        }
        that.promptTitle = `确认(${a})`;
      }, 1000);
    },
    getHorse() {
      if (!this.series) {
        Toast({
          message: "请选择上一级",
          position: "top",
        });
        return;
      }
      this.horseShow = true;
    },
    getDrive() {
      if (!this.horse) {
        Toast({
          message: "请选择上一级",
          position: "top",
        });
        return;
      }
      this.driveShow = true;
    },
    onConfirmDischarge(e) {
      this.discharge = e;
      this.dischargeShow = false;
    },
    // 确认
    async onConfirm(e) {
      this.series = e;
      this.horse = "";
      this.drive = "";
      this.seriesShow = false;
      let list = {
        brand: this.valuationInfo.brand,
        field: "horsepower",
        series: this.series,
        emissionStd: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || '',
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.horseColumns = res.data.result.content;
    },
    async onConfirmHorse(e) {
      this.horse = e;
      this.drive = "";
      this.horseShow = false;
      let list = {
        brand: this.valuationInfo.brand,
        field: "driveType",
        series: this.series,
        driveType: this.drive,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || '',
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.driveColumns = res.data.result.content;
    },
    onConfirmDrive(e) {
      this.drive = e;
      this.driveShow = false;
    },
    onCancel() {
      this.seriesShow = false;
      this.horseShow = false;
      this.driveShow = false;
      this.dischargeShow = false;
    },
    async windowOpen() {
      this.moreShow = true;
      this.horse = "";
      this.drive = "";
      this.series = "";
      this.discharge = "";
      let list = {
        brand: this.valuationInfo.brand,
        field: "series",
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || '',
      };
      const { data: res } = await getImportedVehicleOptions(list);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      // console.log(res);
      this.seriesColumns = res.data.result.content;
    },
    // 点击车辆型号
    onClickVehicleModel() {
      this.vehicleModelHidden = true;
    },
    // 关闭
    onCancelVehicleModel() {
      this.vehicleModelHidden = false;
    },
    // 车辆型号的确认按钮
    onConfirmVehicleModel(event) {
      // console.log(event);
      this.valuationInfo.vehicleModel = event;
      this.vehicleModelHidden = false;
      this.vehicleModelBlur();
    },
    addImage() {
      this.isShowPreview = true;
    },
    // 点击
    touchStart(event) {
      let _this = this;
      clearTimeout(_this.timeOutEvent);
      _this.timeOutEvent = setTimeout(function () {
        _this.timeOutEvent = 0;
        _this.touchPressHidden = true;
      }, 600);
    },
    // 松手
    touchEnd(event) {
      let _this = this;
      clearTimeout(_this.timeOutEvent);
    },
    // 长按 事件
    touchPress(event) {
      let _this = this;
      if (event.name == "取消") {
        this.touchPressHidden = false;
      } else {
        // console.log(window);
        // console.log(window.plus);
        if (!window.plus) return;
        plus.gallery.save(
          this.previewImages[0],
          function () {
            Toast("保存成功!");
            this.touchPressHidden = false;
          },
          function () {
            Toast("保存失败，请重试!");
          }
        );
      }
    },
    // 点击帮助
    helpClick() {
      this.$router.push("/quickValuation/help");
    },
    tips(data) {
      if (data) {
        if (data == 1000) {
          Dialog.alert({
            title: "提示信息",
            message: "验证失败,请返回上级页面重新验证",
          }).then(() => {});
          return false;
        }
        if (data == 1001) {
          Dialog.alert({
            title: "提示信息",
            message: "appKey错误",
          }).then(() => {});
          return false;
        }
        if (data == 1002) {
          Dialog.alert({
            title: "提示信息",
            message: "暂无权限",
          }).then(() => {});
          return false;
        }
        if (data == 1003) {
          Dialog.alert({
            title: "提示信息",
            message: "余额不足",
          }).then(() => {});
          return false;
        }
        if (data == 1004) {
          Dialog.alert({
            title: "提示信息",
            message: "签名已过期",
          }).then(() => {});
          return false;
        }
      }
      return true;
    },
    // 识别ocr
    async touchPhoto(file) {
      //  加了keep-alive 重置页面
      this.cascaderValue = "";
      this.engineBrand = "";
      this.engineModel = "";
      this.drivingTypeSelect = "";
      this.speedRatioSelect = "";
      this.gearboxSelect = "";
      this.valuationInfo = {};
      this.errorClass = 0;
      this.mileage = "";
      this.timeHidden = false;
      // this.getData();
      this.ImgList = [
        { name: "urlPic0", cname: "正前" },
        { name: "urlPic1", cname: "左前侧" },
        { name: "urlPic2", cname: "右前侧" },
        { name: "urlPic3", cname: "左侧" },
        { name: "urlPic4", cname: "右侧" },
        { name: "urlPic5", cname: "正后" },
        { name: "urlPic6", cname: "仪表盘(点火状态)" },
        { name: "urlPic7", cname: "中控" },
        { name: "urlPic8", cname: "排挡杆" },
        { name: "urlPic9", cname: "前轮" },
        { name: "urlPic10", cname: "中轮" },
        { name: "urlPic11", cname: "后轮" },
        { name: "urlPic12", cname: "发动机" },
        { name: "urlPic13", cname: "变速器" },
        { name: "urlPic14", cname: "驱动桥" },
        { name: "urlPic15", cname: "车辆铭牌" },
        { name: "urlPic16", cname: "登记证书" },
        { name: "urlPic17", cname: "其他" },
      ];

      this.imgNumber = 0; //默认0个
      //------
      var that = this; //改变this指向
      var a = 3;
      const param = new FormData();
      param.append("image", file.file);
      param.append("appKey", this.falseData.appKey);
      param.append("sign", this.falseData.sign);
      param.append("randomStr", this.falseData.randomStr);
      param.append("expireTime", this.falseData.expireTime);
      param.append("token", this.falseData.token||'');
      const { data: res } = await ocrConsumer(param);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      var city = res.data.result.locale.split(" ");
      var options = this.options; //获取省市区
      // console.log(options);
      options.forEach((item) => {
        if (item.label === city[0]) {
          item.children.forEach((ite) => {
            if (ite.label === city[1]) {
              ite.children.forEach((i) => {
                if (i.label === city[2]) {
                  this.cascaderValue = i.value;
                }
              });
            }
          });
        }
      });
      this.valuationInfo = res.data.result; //赋值
      // console.log(res.data.result);
      // 驱动形式
      this.drivingTypeColumns = res.data.result.drivingType;
      // 速比
      this.speedRatioColumns = res.data.result.speedRatio;
      // 变速器
      this.gearboxColumns = res.data.result.gearbox;
      // 发动机 改格式
      if (res.data.result.engine.length != 0) {
        var arr = [];
        var i = [];
        res.data.result.engine.forEach((item) => {
          i = [];
          // console.log(item);
          item.engineModels.forEach((ite) => {
            // console.log(ite);
            i.push({
              text: ite.engineName,
            });
          });
          arr.push({
            text: item.engineName,
            children: i,
          });
        });
      }
      this.engineNameColumns = arr;
      this.readOnly = false; //关闭只读

      time(res.data.result.RegisterDate);
      function time(date) {
        date = date.substring(0, 19);
        // console.log(date);
        var timestamp = new Date(date).getTime();
        // 根据毫秒数构建 Date 对象
        var date = new Date(timestamp);
        // 格式化日期
        var dateTime = date.toLocaleString();
        if (dateTime == "Invalid Date") {
          // console.log("时间不对");
          that.timeHidden = true;
        }
      }
      // console.log(res.data.result);
      if (res.data.result.isImported) {
        this.importedModelsShow = true;
        // console.log(this.importedModelsShow);
      } else {
        // 开启 三秒倒计时
        this.promptHidden = true;
        this.isActive = true;
        // 定时器 三秒后关闭
        // console.log("111");
        this.promptTimer = setInterval(function () {
          a--;
          // console.log(a);
          if (a === -1) {
            clearInterval(that.promptTimer);
            that.promptHidden = false;
            that.isActive = false;
            return;
          }
          that.promptTitle = `确认(${a})`;
        }, 1000);
      }
    },
    // 输入车辆型号
    vehicleModelBlur: debounce(async function () {
      // 发动机清空
      this.engineBrand = "";
      this.engineModel = "";
      this.drivingTypeSelect = "";
      this.speedRatioSelect = "";
      this.gearboxSelect = "";

      let valuationInfo = this.valuationInfo;
      var obj = {
        vehicleModel: valuationInfo.vehicleModel,
        registerDate: valuationInfo.RegisterDate,
        local: valuationInfo.locale,
        appKey: this.falseData.appKey,
        sign: this.falseData.sign,
        randomStr: this.falseData.randomStr,
        expireTime: this.falseData.expireTime,
        token: this.falseData.token || '',
      };
      const { data: res } = await getVehicleOptions(obj);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      // console.log(res.data.result);
      // 显示隐藏
      this.valuationInfo.isView = res.data.result.isView;
      // 驱动形式
      this.drivingTypeColumns = res.data.result.drivingType;
      // 速比
      this.speedRatioColumns = res.data.result.speedRatio;
      // 变速器
      this.gearboxColumns = res.data.result.gearbox;
      // 发动机
      if (res.data.result.engine.length != 0) {
        var arr = [];
        var i = [];
        res.data.result.engine.forEach((item) => {
          i = [];
          item.engineModels.forEach((ite) => {
            i.push({
              text: ite.engineName,
            });
          });
          arr.push({
            text: item.engineName,
            children: i,
          });
        });
        this.engineNameColumns = arr;
      }

      // 可以获取接口
    }, 500),
    // 点击注册日期
    RegisterDateClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.RegisterDateHidden = true;
    },
    // 注册日期的取消按钮
    RegisterCancel() {
      this.RegisterDateHidden = false;
    },
    // 注册日期的确定按钮
    RegisterConfirm(event) {
      // console.log(event);
      // console.log(formatDateYMM(event));
      this.valuationInfo.RegisterDate = formatDateYMM(event);
      this.RegisterDateHidden = false;
      this.timeHidden = false;
      this.vehicleModelBlur(); //获取配置选项
    },
    // 点击发证日期
    IssueDateClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.IssueDateHidden = true;
    },
    //发证日期的取消按钮
    IssueCancel() {
      this.IssueDateHidden = false;
    },
    // 发证日期的确认按钮
    IssueConfirm(event) {
      // console.log(formatDateYMM(event));
      this.valuationInfo.IssueDate = formatDateYMM(event);
      this.IssueDateHidden = false;
    },
    // 点击 车辆注册地址
    changeRegin() {
      // console.log(this.readOnly);
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.reginHidden = true;
    },
    // 省市区 选择完成后
    onFinish(event) {
      // console.log(event.selectedOptions);
      // this.valuationInfo.locale = event.selectedOptions
      //   .map((option) => option.name)
      //   .join(" ");
      let citys = [];
      event.selectedOptions.forEach((item) => {
        // console.log(item.label);
        citys.push(item.label);
      });
      // console.log(citys.join(" "));
      // this.cascaderValue = citys.split('').join(' ');
      this.valuationInfo.locale = citys.join(" ");
      this.vehicleModelBlur(); //获取配置选项
      this.reginHidden = false;
    },
    // 点击行驶里程
    mileageClick() {
      let readOnly = this.readOnly;
      if (readOnly) return;
      this.mileageHidden = true;
    },
    // 点击发动机型号
    engineNameClick() {
      //       engineBrand: "东风康明斯"
      // engineModel: "ISDe245 40"
      this.engineNameHidden = true;
    },
    // 发动机型号确定按钮
    engineNameConfirm(event) {
      // console.log(event);

      this.engineBrand = event[0];
      this.engineModel = event[1];
      this.engineNameHidden = false;
    },
    // 点击驱动形式
    drivingTypeClick() {
      this.drivingTypeHidden = true;
    },
    // 驱动形式确认按钮
    drivingTypConfirm(event) {
      // console.log(event);
      this.drivingTypeSelect = event;
      this.drivingTypeHidden = false;
    },
    // 点击速比
    speedRatioClick() {
      this.speedRatioHidden = true;
    },
    // 速比确定按钮
    speedRatioConfirm(event) {
      // console.log(event);
      this.speedRatioSelect = event;
      this.speedRatioHidden = false;
    },
    // 点击变速器
    gearboxClick() {
      this.gearboxHidden = true;
    },
    // 变速器确认按钮
    gearboxConfirm(event) {
      // console.log(event);
      this.gearboxSelect = event;
      this.gearboxHidden = false;
    },
    // 点击添加图片按钮
    addCarImageClick() {
      let imgNumber = this.imgNumber;
      imgNumber = imgNumber + 6;
      this.imgNumber = imgNumber;
    },
    // 上传图片
    async additionalImage(file, detail) {
      // console.log(file.file);
      const param = new FormData();
      param.append("image", file.file);
      param.append("appKey", this.falseData.appKey);
      param.append("sign", this.falseData.sign);
      param.append("randomStr", this.falseData.randomStr);
      param.append("expireTime", this.falseData.expireTime);
      param.append("token", this.falseData.token||'');
      const { data: res } = await upPicture(param);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }

      var ImgList = this.ImgList;
      ImgList.forEach((item, index) => {
        if (item.name == detail.name) {
          item.imgUrl = res.data.imgUrl;
        }
        this.$set(this.ImgList, index, item);
      });
      this.ImgList = ImgList;
    },
    // 删除图片
    imageDelete(data, index) {
      this.ImgList[index].imgUrl = "";
      this.$set(this.ImgList, index, this.ImgList[index]);
    },
    // 点击下一步
    nextStepClick() {
      // console.log(this.valuationInfo.locale);
      // console.log(this.valuationInfo);
      // 校验行驶证
      if (JSON.stringify(this.valuationInfo) == "{}") {
        Dialog.alert({
          title: "提示",
          message: "请您上传行驶证",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      // 检验行驶里程
      if (this.mileage == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写行驶里程",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // 车辆注册地址
      if (!this.valuationInfo.locale) {
        // console.log(123);
        Dialog.alert({
          title: "提示",
          message: "请您选择车辆注册地址",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // 车牌号码为空
      if (this.valuationInfo.PlateNo == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写车牌号码",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;
        return;
      }
      // 车辆类型
      if (this.valuationInfo.VehicleType == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆类型",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      // 品牌车型为空
      if (this.valuationInfo.vehicleModel == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆型号",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      // 车辆识别代号为空
      if (this.valuationInfo.Vin == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写车辆识别代号",
          confirmButtonColor: "#507496",
        }).then(() => {});
        this.errorClass = 0;

        return;
      }
      var time = /^(\d{4})-(\d{2})-(\d{2})$/;
      // 注册日期
      if (this.valuationInfo.RegisterDate == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写注册日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      if (!time.test(this.valuationInfo.RegisterDate)) {
        Dialog.alert({
          title: "提示",
          message: "请确认注册日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }

      // 发证日期
      if (this.valuationInfo.IssueDate == "") {
        Dialog.alert({
          title: "提示",
          message: "请您填写发证日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      if (!time.test(this.valuationInfo.IssueDate)) {
        Dialog.alert({
          title: "提示",
          message: "请确认发证日期",
          confirmButtonColor: "#507496",
        }).then(() => {});
        return;
      }
      // 如果用户上传了图片，过滤出来
      let obj = {};
      var filterList = this.ImgList.filter((item) => {
        return item.imgUrl;
      });
      //如果用户填加照片之后
      if (filterList.length != 0) {
        filterList.forEach((item) => {
          obj[item.name] = item.imgUrl;
        });
        // console.log(obj);
        this.valuationInfo.charts = {
          ...obj,
        };
        // console.log(this.valuationInfo);
      }
      //       engineBrand: "", //发动机品牌
      // engineModel: "", // 发动机型号
      //         drivingTypeSelect: "", //选择驱动
      //   speedRatioSelect: "", //选择速比
      //  gearboxSelect: "", //选择变速器
      this.valuationInfo.engineBrand = this.engineBrand;
      this.valuationInfo.engineModel = this.engineModel;
      this.valuationInfo.drivingTypeSelect = this.drivingTypeSelect;
      this.valuationInfo.speedRatioSelect = this.speedRatioSelect;
      this.valuationInfo.gearboxSelect = this.gearboxSelect;

      this.valuationInfo = Object.assign(this.valuationInfo, this.falseData);
      this.valuationInfo.mileage = this.mileage;
      localStorage.setItem("valuationInfo", JSON.stringify(this.valuationInfo));
      this.$router.push("/quickValuation/secondarySubmit");
      return;
    },
    async getData() {
      this.ImgList = this.pic.data.charts;
      // console.log(this.ImgList);
      this.options = city;
      let falseData = {
        appKey: this.$store.state.params.appKey,
        sign: this.$store.state.params.sign,
        randomStr: this.$store.state.params.randomStr,
        expireTime: this.$store.state.params.expireTime,
        token: this.$store.state.params.token||'',
      };
      // let falseData = {
      //   appKey: "cada417a94357a9521ebf1b7",
      //   sign: "de83da26c62673c9c1c805c5b513425f",
      //   randomStr: "12345678",
      //   expireTime: "1658303858000",
      //   token: '123125634165324651',
      // };
      this.falseData = falseData;
      // console.log(this.falseData);
      // console.log(this.$route.query);
    },
  },
  beforeCreate() {},
  created() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s4.cnzz.com/z_stat.php?id=1281102051&web_id=1281102051";
    document.body.appendChild(s);
    // console.log(document.body);

    this.$store.commit("setParams", this.$route.query);
  },
  beforeMount() {},

  mounted() {
    let str = unescape(
      "%3Cspan id='cnzz_stat_icon_1281102051'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1281102051' type='text/javascript'%3E%3C/script%3E"
    );
    this.statistics = str;
    // this.$refs.home.appendChild(spanContent);
    this.getData();
    // console.log(this.$store.state.params);
    // console.log(JSON.stringify(this.$store.state.params));
  },

  beforeUpdate() {},

  updated() {},

  activated() {},

  deactivated() {},

  beforeDestroy() {},

  destroyed() {
    // console.log('离开页面了')
    // this.promptTimer = null
    // console.log(this.promptTitle)
  },
};
</script> 

<style scoped lang="less">
#app {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  /* padding-top: 10px; */
  background: #f2f2f2;
  font-family: PingFang-SC-Bold, PingFang-SC;
  overflow: hidden;
}
/* 顶部实例 */
.instance {
  width: 100%;
  height: 50px;
  /* margin: 0 auto; */
}
/* ocr */
.ocrIdentify {
  width: 100%;
  height: 197px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.ocrIdentifyTop {
  width: 100%;
  height: 24px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.title {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  box-sizing: border-box;
  padding-left: 10px;
}
.help {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  padding-right: 5px;
}
.ocrImageView {
  width: 100%;
  height: 143px;
}
.ocrImageView > .van-uploader {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__input-wrapper {
  width: 100%;
  height: 100%;
}
/* 车辆信息 */
.carInfoView {
  margin-top: 10px;
  width: 100%;
  height: 378px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 0;
}
.zixiSecondFlex {
  position: absolute;
  right: 40px;
  font-size: 14px;
  color: #fe4000;
}
.carInfoViewLi {
  width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.carInfoViewLiText {
  width: 114px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.carInfoViewLiActive {
  color: #fe4000 !important;
}
.carInfoViewLi > input {
  border: none;
  width: 186px;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.basisInfoView {
  width: 100%;
  height: 104px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}

/* 基础信息 */
.basisInfoViewLi {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
}
.basisInfoViewLiText {
  min-width: 88px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
.basisInfoViewLi > input {
  border: none;
  // flex: 1;
  width: 100%;
  font-size: 14px;
  height: 24px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
/* 车辆配置选项 */
.configurationView {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.configureCen {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.picker {
  overflow: hidden;
  margin: 0 30px;
}
.pickerLi {
  width: 104px;
  background: rgba(1, 54, 103, 0.69);
  border-radius: 6px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 6px;
}
.pickerLi > div {
  flex: 1;
  text-align: center;
}
.pickerText {
  width: 104px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  text-align: center;
}
/* 车辆图片 */
.carImageView {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
}
.carImageBtn {
  width: 100%;
  height: 40px;
  background: rgba(1, 54, 103, 0.69);
  border-radius: 6px;
  border: none;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}
.carImageViewFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.carImageViewFlexLi {
  width: 163px;
  height: 140px;
  /* background: rgba(1, 54, 103, 0.3);
  border-radius: 10px; */
}
.carImageViewFlexLi .image {
  width: 163px;
  height: 108px;
  background: rgba(1, 54, 103, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carImageViewFlexLi .text {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 10px;
}
.remoteImage {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  z-index: 99999;
}
/* 下一步 */
.nextStep {
  border: none;
  width: 100%;
  height: 44px;
  background: #013667;
  border-radius: 6px;
  margin: 28px 0 22px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.promptView {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.promptTextSize {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.mar10 {
  margin-top: 10px;
}
.g-content {
  font-size: 16px;
}
.g-title {
  text-align: center;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
}
.g-brand {
  width: 100vw;
  display: flex;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 20px;
}
.g-car {
  display: block;
}
.g-name {
  width: 60px;
}
.g-items {
  display: flex;
}

.g-select {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.g-select > div:nth-of-type(2) {
  flex: 1;
  text-align: right;
}
.g-tips {
  font-size: 14px;
  font-weight: 400;
  color: #5a6f83;
  text-indent: 20px;
  margin-top: 20px;
}
.g-btn {
  width: 250px;
  height: 44px;
  background: #013667;
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
}
// a {
//   display: none ;
// }
</style>
